import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, DateField, TextInput, useNotify, useDataProvider, usePermissions } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from './components/ListActions';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import { BulkDeleteButton, useRefresh } from 'react-admin';
import { useHistory } from "react-router-dom";
import { POST_OFF_RESOURCE } from "../../providers/nestjs_crud";
import { Permissions } from "../../permissions/permissions";
import DangerousIcon from "@material-ui/icons/Warning";

function CorporateCreditFilter(props) {
	return (
		<Filter {...props}>
			<TextInput alwaysOn label="Nome" source="name" />
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}
export function CorporateCreditList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}  filters={<CorporateCreditFilter />}>
			<Datagrid rowClick={null}>
				<TextField source="name" />
				<TextField source="credit"></TextField>
				<TextField source="enable"></TextField>
				<TextField source="status"></TextField>
				<TextField source="timeIntervalType"></TextField>
				<DateField source="created_at" />
				<ButtonList />
				<EditButton></EditButton>
				<ShowButton></ShowButton>
        <EnableDangerous />
			</Datagrid>
		</List>
	);
}

export function ButtonList({ record = {} }) {
    let history = useHistory();

    return (
        <Button variant="contained" color="primary" endIcon={<SettingsIcon/>} onClick={() => {
            history.push(`/corporateCreditCondition?filter={"corporateCreditId||eq":"${record.id}"}`);
        }}>Condizioni</Button>
    );
}

function EnableDangerous({ record = {} }) {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  let { permissions } = usePermissions();
  const refresh = useRefresh();

  if(record.enable === 'DISABLED' && permissions &&
    permissions.includes(
      `${Permissions.corporateCredit.feature}-${Permissions.corporateCredit.actions.enableDangerous}`
    )) {
      return (
          <Button
            variant="text"
            color="primary"
            endIcon={<DangerousIcon />}
            onClick={() => {

              var confirm = window.confirm("Sei sicuro di voler abilitare questo corporate credit?");
              if (confirm) {
                dataProvider(
                  POST_OFF_RESOURCE,
                  `${Permissions.corporateCredit.feature}/${Permissions.corporateCredit.actions.enableDangerous}/${record.id}`,
                  {
                    data: {},
                  }
                )
                  .then(({ data }) => {
                    notify(`Corporate credit abilitato`);
                    refresh()
                  })
                  .catch((error) => {
                    alert(error);
                  });
              }
            }}
          >
            Abilita
      </Button>
    );
  }
  return null;
}
