import React from 'react';
import { Datagrid, List, TextField, TextInput, ShowButton } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const PriceListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
    </Filter>
);

export const PriceListList = props => (
    <List {...props} actions={<ListActions />}  filters={<PriceListFilter />} >
        <Datagrid rowClick={null}>
            <TextField label="Nome" source="name" />
			<ShowButton />
            <ButtonList />
        </Datagrid>
    </List>
);


function ButtonList({ record = {} }) {
    let history = useHistory();
    return (
        <Button variant="contained" color="primary" endIcon={<ShoppingCartIcon/>} onClick={() => {
            history.push(`/itemSaleablePriceList?filter={"priceListId||eq":${record.id}}`);
        }}>Item Vendibili</Button>
    );
}