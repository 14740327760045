import React from 'react';
import { Datagrid, List, TextField, TextInput, AutocompleteInput, DateTimeInput, DateField } from 'react-admin';
import Filter from '../../../inheritance/BaseFilter';
import ListActions from '../../../../components/ListActions';
import { TYPES } from './enum';

const PaymentAttemptFilter = (props) => (
    <Filter {...props}>
        <TextInput label="uuid" source="uuid||eq" alwaysOn />
        <TextInput label="providerPaymentAttemptId" source="providerPaymentAttemptId||eq"/>
        <TextInput label="transactionParentId" source="transactionParentId||eq"/>
        <TextInput label="transactionId" source="transactionId||eq"/>
        <AutocompleteInput label="type" source="type" choices={TYPES.map(type => {
            return {
                id: type,
                name: type
            }
        })} />
        <DateTimeInput 
          source={`created_at||gte`}
          label={`data di creazione Da`}
        />
        <DateTimeInput 
          source={`created_at||lte`}
          label={`data di creazione A`}
        />
    </Filter>
);


export const PaymentAttemptList = props => (
    <List {...props} sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}  filters={<PaymentAttemptFilter />}>
        <Datagrid rowClick="show">
            <TextField label="uuid" source="uuid" />
            <DateField label="date" source="created_at" showTime={true} />
            <TextField label="transactionParentId" source="transactionParentId" />
            <TextField label="transactionId" source="transactionId" />
            <TextField label="mainUserId" source="mainUserId" />
            <TextField label="providerPaymentAttemptId" source="providerPaymentAttemptId" />
            <TextField label="type" source="type" />    
        </Datagrid>
    </List>
);