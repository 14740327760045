import React, { useState, useEffect } from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceInput, ReferenceField, AutocompleteInput, DateField, DateTimeInput, AutocompleteArrayInput,useDataProvider, NumberInput, useListContext, minLength, email } from 'react-admin';

import Filter from '../inheritance/BaseFilter';
import { ORDER_TYPOLOGIES as Typologies} from '../../utils/enum/Typology';
import ListActions from '../../components/ListActions';
import { Permissions } from '../../permissions/permissions';
import { GET_OFF_RESOURCE } from '../../providers/nestjs_crud';

import { useForm } from 'react-final-form';
import AsyncSelect from 'react-select/async';
import { makeStyles } from '@material-ui/core/styles';

import { debounce } from '../../utils/debounce';

export const STATUS_CANCELLED = 'cancelled';
export const STATUS_DRAFT = 'draft'
export const STATUS_AWAITING_DELIVERY = 'awaiting_delivery';
export const STATUS_COMPLETED = 'completed';

const useStyles = makeStyles((theme) => ({
  filterBox: {
    minWidth: '300px',
    zIndex: theme.zIndex.appBar,
    marginTop: '24px',
    paddingBottom: '16px'
  },
}));

const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? '#086d40' : 'rgba(0, 0, 0, 0.2)',
      boxShadow: state.isFocused ? `0 0 0 1px #086d40` : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#086d40' : 'rgba(0, 0, 0, 0.2)',
      },
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? '#086d40' : isFocused ? '#bde4c9' : null,
      color: isSelected ? 'white' : 'black',
      ':active': {
        backgroundColor: !isSelected ? '#bde4c9' : '#086d40',
      },
    }),
};

function CustomSearch() {
    const dataProvider = useDataProvider();
    const [state, setState] = useState("INITIAL");
    const [value, setValue] = useState();
    const [defaultOptions, setDefaultOptions] = useState([]);
    const listContext = useListContext();
    const form = useForm();
    const classes = useStyles();

    useEffect(() => {
        if (state === "INITIAL" && listContext.filterValues["mainUserId||eq"]) {
            setState("FETCHING_MAIN_USER");
            dataProvider.getList(Permissions.B2CUser.feature, {
                filter: {
                    mainUserId: listContext.filterValues["mainUserId||eq"]
                },
                pagination: { page: 1, perPage: 1 }
            })
                .then(({ data }) => {
                    setValue({ value: data[0].mainUserId, label: data[0].email });
                    setDefaultOptions([{ value: data[0].mainUserId, label: data[0].email }]);
                    setState("READY");
                })
                .catch(() => {
                    listContext.setFilters({
                        ...listContext.filterValues,
                        "mainUserId||eq": null
                    }, listContext.displayedFilters, true);
                })
        }
        if (state === "START_FILTER") {
            setState("FILTERING");
            listContext.setFilters({
                ...listContext.filterValues,
                "mainUserId||eq": value?.value
            }, listContext.displayedFilters, true);
            setState("FILTERED");
        }

    }, [value, state, form, listContext, dataProvider]);

    const debouncedLoadOptions = debounce((inputValue, callback) => {
        if (inputValue.length >= 5) {
            dataProvider.getList(Permissions.B2CUser.feature, {
                filter: { "email": inputValue },
                pagination: { page: 1, perPage: 5000 }
            })
            .then(({ data }) => {
                callback(data.map(item => ({ value: item.mainUserId, label: item.email })));
            })
            .catch(e => {
                callback(defaultOptions);
            });
        } else {
            callback(defaultOptions);
        }
    }, 1000);

    return <div className={classes.filterBox}>
        <AsyncSelect
            styles={customStyles}
            noOptionsMessage={() => "Nessun utente trovato"}
            isClearable
            isSearchable={true}
            placeholder="Inserisci l'email..."
            loadOptions={debouncedLoadOptions}
            defaultOptions={defaultOptions}
            value={value}
            onChange={e => {
                setState("START_FILTER");
                setValue(e);
            }}
        />
    </div>
}

const SellingOrderFilter = (props) => {
    const dataProvider = useDataProvider();
    const [dataStatus, setDataStatus] = useState([]);

    useEffect(() => {
        dataProvider(GET_OFF_RESOURCE,`${Permissions.sellingOrder.feature}_${Permissions.sellingOrder.subFeatures.orderStateMachine.name}` )
            .then(({data}) => {
                setDataStatus(Object.keys(data).map(statusKey => {
                    return {
                        id: data[statusKey],
                        name: data[statusKey].toUpperCase()
                    }
                }))
            });
    }, [dataProvider, setDataStatus]);

    return (
    <Filter {...props}>
        <CustomSearch alwaysOn/>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Luogo di Vendita"
            source="placeId||eq"
            reference={`${Permissions.place.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Punto di Vendita"
            source="sellingPointId||eq"
            reference={`${Permissions.sellingPoint.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <AutocompleteInput label="Tipologia" source="typology" choices={Typologies.map(typology => {
            return {
                id: typology,
                name: typology
            }
        })} />
        <DateTimeInput 
          alwaysOn
          source={`created_at||gte`}
          label={`data di creazione Da`}
        />
        <DateTimeInput 
          source={`created_at||lte`}
          label={`data di creazione A`}
        />
        <AutocompleteArrayInput
            label="Stato"
            source="orderStateMachine.status||in"
            choices={dataStatus}
            translateChoice={false}
            allowEmpty={true} />
        <NumberInput label="Transaction ID" source="transactionId||eq" />
        <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
        <TextInput validate={[minLength(5), email()]} label="Nome (email)" source="name" />
    </Filter>
)};
export function SellingOrderList(props) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    now.setDate(now.getDate() - 7);

    return (
        <List debounce={1000} {...props} filterDefaultValues={{ 'created_at||gte': now.toJSON() }} pagination={null} perPage={100} actions={<ListActions />}  sort={{ field: 'id', order: 'DESC' }} filters={<SellingOrderFilter />} >
            <Datagrid rowClick="show">
                <TextField label="ID" source="id" sortable={false} />
                <TextField label="Nome" source="name" sortable={false} />
                <DateField label="Creato il" source="created_at" sortable={false} />
                <TextField label="Tipologia" source="typology" sortable={false} />
                <ReferenceField label="Luogo di Vendita" source="placeId" reference="place" link="show" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Punto di Vendita" source="sellingPointId" reference="sellingPoint" link="show" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField label="Stato Ordine" source="orderStateMachine.status" sortable={false}  />
                <TextField source="mainUserId" sortable={false} />
            </Datagrid>
        </List>
    );
}