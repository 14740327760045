import React, { useState, useEffect } from 'react';
import { AppBar, useRefresh } from 'react-admin';
import { Menu, MenuItem, Button, Box } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import { useHistory } from "react-router-dom";

export default function AppBarWithHubSelection(props) {
  const [status, setStatus] = useState('GET_TOKEN_DATA');
  const [tokenData, setTokenData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hubSelected, setHubSelected] = useState();
  const history = useHistory();
  const refresh = useRefresh();

  useEffect(() => {
    if (status === 'GET_TOKEN_DATA') {
    const token = localStorage.getItem('token');

      if (!token) {
        setStatus('ERROR_GETTING_TOKEN_DATA');
        return null;
      }
      setStatus('GETTING_TOKEN_DATA');

      try {
        const base64Payload = token.split('.')[1];
        const payloadBuffer = Buffer.from(base64Payload, 'base64');
        const data = JSON.parse(payloadBuffer.toString());
        if (data.hubs === undefined || data.hubs === null || data.accessAllHubs === undefined || data.accessAllHubs === null) {
            /**
             * to get local error if no hub linked
             * history.push(`/#/unauthorized?permission="no-hub-linked"`);
             */
            setStatus('ERROR_GETTING_TOKEN_DATA');
            return;
        }
        localStorage.removeItem('hubSelected');
        setTokenData(data);
        const noneChoice = {
          'id': 'NONE',
          'label': 'Tutti gli hub'
        };

        data.hubs.push(noneChoice);
        setHubSelected(noneChoice);
        setStatus('TOKEN_DATA_READY');
      } catch {
        setStatus('ERROR_GETTING_TOKEN_DATA');
      }
    }
  }, [history, status]);

  return (
    <AppBar {...props}>
      <Box display='flex' justifyContent='flex-end' flex={1}>
        {status === 'TOKEN_DATA_READY' && tokenData && <>
          <Button startIcon={<DeviceHubIcon />} endIcon={<ArrowDropDownIcon />} onClick={(event) => { setAnchorEl(event.currentTarget) }} variant="outlined" color="inherit">
            {hubSelected.label}
          </Button>
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: 'center',
            }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {tokenData.hubs.map((hub) => {
              return <MenuItem onClick={() => {
                setAnchorEl(null);
                setHubSelected(hub);
                if(hub.id === 'NONE') {
                  localStorage.removeItem('hubSelected');
                } else {
                  localStorage.setItem('hubSelected', hub.id);
                }
                refresh();
              }} disabled={hub.id === hubSelected.id} key={hub.id}>{hub.label}</MenuItem>;
            })}
          </Menu>
        </>}
      </Box>
    </AppBar>
  );
};