import React from "react"
import { TextField, DateField, EditButton, ShowButton, usePermissions } from 'react-admin';
import { ListPreview } from '../../../../../layouts/ListPreview';
import { Permissions } from '../../../../../permissions/permissions';
import { JobTaskCreate } from "./JobTaskCreate";
import { Box, Typography } from '@material-ui/core'; 
import { missionStatesValidForCreateOrUpdateJobTask } from './config';

export default function JobTaskList (props) {
    const { permissions } = usePermissions();

    return (
        <ListPreview
            title={"Tasks"}
            basePath = {`/${Permissions.jobTask.feature}`}
            resource = {`${Permissions.jobTask.feature}`}
            filterList={{ "missionJobId||eq": props.id}}
            hasCreateModal={permissions && permissions.includes(`${Permissions.jobTask.feature}-${Permissions.jobTask.actions.CreateOne}`) && props.missionJob &&  props.missionJob.mission && props.missionJob.mission.stateMachine && missionStatesValidForCreateOrUpdateJobTask.includes(props.missionJob.mission.stateMachine.status)}
            createProps={{ missionJobId: props.id }}
            create={JobTaskCreate}
            rowClick={null}
            reactAdminListProps={{
                bulkActionButtons: false,
                sort: false,
                pagination: false,
            }}
            redirect={`/${Permissions.missionJob.feature}/${props.id}/show`}
        >
            <TextField sortable={false} label="Type" source="label" />
            <TextField sortable={false} label="Executor Main User ID" source="executorMainUserId" />
            <JobTaskDuration label='Duration' />
            <DateField sortable={false} locales="it-IT" label="Start Date" source="startDate" showTime />
            <TextField sortable={false} label="Output Label" source="ouputLabel" />
            <TextField sortable={false} label="Job Stage Name" source="jobStageName" />
            <TextField sortable={false} label="Job Stage Sub-Stage" source="jobStageSubStage" />
            <TextField sortable={false} label="Job Stage Inter-Stage Order" source="jobStageInterStageOrder" />
            <TextField sortable={false} label="Execution" source="execution" />
            <TextField sortable={false} label="STATO" source="stateMachine.status" />
            <ControlledEditButton listProps={props} />
			{permissions && permissions.includes(`${Permissions.jobTask.feature}-${Permissions.jobTask.actions.ReadOne}`) && <ShowButton />}
        </ListPreview>
    )
}

function ControlledEditButton(props) {
    const { permissions } = usePermissions();

    if(permissions && permissions.includes(`${Permissions.jobTask.feature}-${Permissions.jobTask.actions.UpdateOne}`) && props.record.creationTrigger === 'manual' && (props.listProps.missionJob &&  props.listProps.missionJob.mission && props.listProps.missionJob.mission.stateMachine && missionStatesValidForCreateOrUpdateJobTask.includes(props.listProps.missionJob.mission.stateMachine.status))) {
        return (
            <EditButton {...props} />
        );
    }

    return null;
}

export function JobTaskDuration(props) {
	const seconds = props.record.duration
	const minutes = Math.floor(seconds / 60) % 60;
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 60;
	return (
		<Box>
			<Typography variant='subtitle2' >{`${hours}h ${minutes}m ${remainingSeconds}s`}</Typography>
		</Box> 
	);
}