import React, { useState, useEffect } from 'react';
import { Datagrid, List, TextField, ReferenceField, DateField, ReferenceInput, AutocompleteInput, AutocompleteArrayInput, useDataProvider, DateTimeInput, TextInput, showNotification, useRefresh, LinearProgress, NumberInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';
import InputSelectRest from './components/InputSelectRest';
import { GET_OFF_RESOURCE, POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import Button from '@material-ui/core/Button';
import QueueIcon from '@material-ui/icons/Queue';
import { useHistory } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Add as AddIcon, Sort as SortIcon } from '@material-ui/icons';
import { transferTypesEligibleForDelivery, mapTypeToShortName } from './';

const TransferFilter = (props) => {
    const dataProvider = useDataProvider();
    const [dataStatus, setDataStatus] = useState([]);

    useEffect(() => {
        dataProvider(GET_OFF_RESOURCE,`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.transferStateMachine.name}` )
            .then(({data}) => {
                setDataStatus(Object.keys(data).map(statusKey => {
                    return {
                        id: data[statusKey],
                        name: data[statusKey].toUpperCase()
                    }
                }))
            });
    }, [dataProvider, setDataStatus]);

    return (
    <Filter {...props}>
        <NumberInput label="ID" source="id||eq" alwaysOn/>
        <InputSelectRest label="Tipo di Trasferimento" source="type"></InputSelectRest>
        <ReferenceInput
			filterToQuery={searchText => {
				if(!searchText)
					return {};

				return {
					"name": searchText,
					
				}
			}}
            label="Trasferimento Da"
            source="fromWarehouseLocationId||eq"
            reference={`${Permissions.warehouseLocation.feature}`} >
			<AutocompleteInput
				optionText="name"
				optionValue="id" />
		</ReferenceInput>
        <ReferenceInput
			filterToQuery={searchText => {
				if(!searchText)
					return {};

				return {
					"name": searchText,
					
				}
			}}
            label="Trasferimento a"
            source="toWarehouseLocationId||eq"
            reference={`${Permissions.warehouseLocation.feature}`}>
			<AutocompleteInput
				optionText="name"
				optionValue="id" />
		</ReferenceInput>
        <AutocompleteArrayInput
            label="Stato"
            source="transferStateMachine.status||in"
            choices={dataStatus}
            translateChoice={false}
            allowEmpty={true}/>
        <ReferenceInput
            filter={{"authType||eq": "GOOGLE"}}
            filterToQuery={searchText => {
                if(!searchText)
                    return null;

                return {
                    "email": searchText,
                    authType: "GOOGLE"
                }
            }}
            source="assigneeId||eq"
            reference={`${Permissions.user.feature}`}
        >
        <AutocompleteInput
            optionText="email"
            optionValue="id" />
		</ReferenceInput>
        <DateTimeInput
          source={`expectedDate||gte`}
          label={`data prevista Da`}
        />
        <DateTimeInput
          source={`expectedDate||lte`}
          label={`data prevista A`}
        />
        <TextInput label="External document" source="externalDocument"></TextInput>
        <ReferenceInput
            label="Corriere"
            filterToQuery={searchText => {
                if(!searchText)
                    return {
                        "type": "RibesUser"
                    };

                return {
                    "label": searchText,
                    "type": "RibesUser"
                }
            }}
            source="deliveryMainUserId||eq"
            reference={`${Permissions.mainUser.feature}`}
        >
        <AutocompleteInput
            optionText={(record)=> {
                if(!record || !record.id) {
                    return 'cancel';
                }
                return `${record ? record.id : ''} ${record ? record.label : ''}`
            }}
            optionValue="id" />
		</ReferenceInput>
        <ReferenceInput
            label="Location di Uscita"
            filterToQuery={searchText => {
                if(!searchText)
                    return {
                        "type": "RibesUser"
                    };

                return {
                    "label": searchText,
                    "type": "RibesUser"
                }
            }}
            source="currentOutboundLocationId||eq"
            reference={`${Permissions.mainUser.feature}`}
        >
        <AutocompleteInput
            optionText={(record)=> {
                if(!record || !record.id) {
                    return 'cancel';
                }
                return `${record ? record.label : ''}`
            }}
            optionValue="id" />
		</ReferenceInput>
        <AutocompleteInput
            label="Stato Ordinamento"
            source="outboundSortingStatus"
            choices={[{
                id:'sorted',
                name: 'ORDINATO'
            },{
                id: 'toSort',
                name: 'DA ORDINARE'
            }]} />
        <AutocompleteInput
            label="Urgenza"
            source="priority||eq"
            choices={[{
                id:'urgent',
                name: 'Urgente'
            },{
                id: 'not-urgent',
                name: 'Non Urgente'
            }]} />
        <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
        <NumberInput label="transactionId" source="transactionId||eq"/>
    </Filter>
    );
}
export function TransferList(props) {
    const history = useHistory();
    return (
        <List {...props} bulkActionButtons={false} actions={<ListActions customCreateButton={<Button
            color="primary"
            size="small"
            onClick={() => {
                history.push('/v2/transfer/create');
            }}><AddIcon /> CREATE</Button>}/>} filters={<TransferFilter />} sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid rowClick={null}>
                <TextField source="id" />
                <TransferType label="Tipo" />
                <ReferenceField label="da" source="fromWarehouseLocationId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="a" source="toWarehouseLocationId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
                    <TextField source="name" />
                </ReferenceField>
                <DateField showTime label="Data prevista" source="expectedDate" />
                <TextField label="comment" source="externalDocument"></TextField>
                <TextField label="Stato" source="transferStateMachine.status" />
                <Actions label="Azioni"/>
            </Datagrid>
        </List>
    );
}

function Actions({ source, record = {} }) {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const [status, setStatus] = useState('INITIAL');

    if(['ACTION_IN_PROGRESS'].includes(status)) {
        return <LinearProgress /> ;
    }

    return <>
        <Button
            variant="outlined"
            onClick={() => {
                history.push(`/transfer/${record.id}/show`);
            }}
            color="primary"
            startIcon={<VisibilityIcon />}>
            SHOW
        </Button>
        {record.type !== 'SupplierReception' && record.transferStateMachine && record.transferStateMachine.status === 'wip' &&
            <Button
                style={{marginLeft: '8px'}}
                variant="outlined"
                onClick={() => {
                    history.push(`/picking/${record.id}`);
                }}
                color="primary"
                startIcon={<QueueIcon />}>
                PICK
            </Button>
        }
        {record.type !== 'SupplierReception' && record.transferStateMachine && ['picked', 'to_finish'].includes(record.transferStateMachine.status) && record.outboundSortingStatus === 'toSort' &&
            <Button
                style={{marginLeft: '8px'}}
                variant="outlined"
                onClick={() => {
                    setStatus('ACTION_IN_PROGRESS');
                    dataProvider(POST_OFF_RESOURCE, `${Permissions.transfer.feature}/assignOutboundLocation`, {
                        data: {
                            transferId: record.id
                        }
                    })
                        .then(({data}) => {
                            if(data && data.success) {
                                setStatus('INITIAL');
                                dispatch(showNotification(`azione completata: assignOutboundLocation`));
                                refresh();
                            } else {
                                throw new Error('errore esecuzione azione: assignOutboundLocation');
                            }
                        })
                        .catch((error) => {
                            setStatus('INITIAL');
                            let errorString = 'Errore processamento richiesta';
                            if(typeof error === 'string') {
                                errorString = error;
                            } else if(error && error.message) {
                                errorString = error.message
                            } else if(error) {
                                errorString = JSON.stringify(error);
                            }
                            dispatch(showNotification(errorString, 'warning'));
                        });
                }}
                color="primary"
                startIcon={<SortIcon />}>
                SORT
            </Button>
        }
        {transferTypesEligibleForDelivery.includes(record.type) && record.transferStateMachine && record.transferStateMachine.status === 'picked' && record.outboundSortingStatus === 'sorted' && <Button
            style={{marginLeft: '8px'}}
            variant="outlined"
            onClick={() => {
                setStatus('ACTION_IN_PROGRESS');
                dataProvider(GET_OFF_RESOURCE, `transfer_transferStateMachine/${record.transferStateMachine.id}/transition/transition_start_delivery`)
                    .then(({ data }) => {
                        setStatus('INITIAL');
                        dispatch(showNotification(`transazione: transition_start_delivery completata`));
                        refresh();
                    })
                    .catch(error => {
                        setStatus('INITIAL');
                        dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
                    })
            }}
            color="primary"
            startIcon={<LocalShippingIcon />}>
            INIZIA DELIVERY
        </Button>}
    </>;
}

function TransferType({ source, record = {} }) {
    return <span>{mapTypeToShortName[record.type] || record.type}</span>;
}
