import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceInput, AutocompleteInput, ReferenceField } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import Typologies from '../../utils/enum/Typology';
import ListActions from '../../components/ListActions';
import { Permissions } from '../../permissions/permissions';

const SellingPointFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />        
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Luogo"
            source="placeId||eq"
            reference={`${Permissions.place.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <AutocompleteInput label="Tipologia" source="typology" choices={Typologies.map(typology => {
            return {
                id: typology,
                name: typology
            }
        })} />
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return {
                    "name": searchText,
                }
            }}
            label="Hub di riferimento"
            source="hubId"
            reference={Permissions.hub.feature}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            emptyText="cancel"
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return {
                    "name": searchText,
                }
            }}
            label="Layout"
            source="layoutId"
            reference={Permissions.layout.feature}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
        </ReferenceInput>
        <TextInput label="Legacy IDs" source="legacyIDs" />
    </Filter>
);

export const SellingPointList = props => (
    <List {...props} actions={<ListActions />}  filters={<SellingPointFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField label="Luogo" source="placeId" reference="place" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Tipologia" source="typology" />
            <ReferenceField label="Layout" source="layoutId" reference="layout" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Negozio" source="sellingPointImpulse.open" />
        </Datagrid>
    </List>
);