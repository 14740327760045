import React from 'react';
import { Datagrid, List, TextField, TextInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';

const SupplierFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
    </Filter>
);

export const SupplierList = props => (
    <List {...props} actions={<ListActions />}  filters={<SupplierFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Email" source="email" />
            <TextField label="Città" source="city" />
        </Datagrid>
    </List>
);