import React from 'react';
import { Datagrid, List, TextField, ReferenceField, TextInput, DateTimeInput, DateField, SelectInput, NumberInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';

const WalletManualTransferFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ID" source="id||eq" alwaysOn />
        <TextInput label="transactionId" source="transactionId||eq" alwaysOn />
        <SelectInput
            fullWidth
            label="Tipo"
            source="type"
            choices={[
                {
                    id: 'credit', 
                    name: 'Carica'
                },
                {
                    id: 'debit', 
                    name: 'Svuota'
                },
            ]}
            alwaysOn />
        <DateTimeInput 
          source={`created_at||gte`}
          label={`data da`}
        />
        <DateTimeInput 
          source={`created_at||lte`}
          label={`data a`}
        />
        <NumberInput label="Transaction ID" source="transactionId||eq" />
        <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
    </Filter>
);

export const WalletManualTransferList = props => (
    <List {...props} actions={<ListActions />}  filters={<WalletManualTransferFilter />} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <DateField
                showTime={true}
                source={`created_at`}
                label={`creazione`} />
            <TextField label="type" source="type" />
            <ReferenceField label="Utente" source="userId" reference={`${Permissions.user.feature}`} link={null}>
                <TextField source="email" />
            </ReferenceField>
            <TextField label="transactionId" source="transactionId" />
            <TextField label="comment" source="comment" />
        </Datagrid>
    </List>
);