import React, { useState, useEffect }  from 'react';
import { Datagrid, List, TextField, Filter, DateTimeInput, AutocompleteArrayInput, ReferenceInput, AutocompleteInput, TextInput, useDataProvider, useRefresh, usePermissions, showNotification } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Permissions, CorporateCreditHistoryActions } from '../../../permissions/permissions';
import { POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import { useDispatch } from 'react-redux';

function HistoryFilters(props) {
    return (
        <Filter {...props}>
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return { name: searchText }
                }}
                label="Credito"
                source="corporateCreditId||eq"
                reference={`${Permissions.corporateCredit.feature}`}
            >
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <DateTimeInput 
                source={`created_at||gte`}
                label={`data di creazione Da`}
            />
            <DateTimeInput 
                source={`created_at||lte`}
                label={`data di creazione A`}
            />
            <DateTimeInput 
                source={`deliveryDate||gte`}
                label={`consegna Da`}
            />
            <DateTimeInput 
                source={`deliveryDate||lte`}
                label={`consegna A`}
            />
            <AutocompleteArrayInput
                label="Stato"
                source="status||in"
                choices={[
                    {
                        name: 'PENDING',
                        id: 'PENDING',
                    },
                    {
                        name: 'RESERVED',
                        id: 'RESERVED',
                    },
                    {
                        name: 'COMPLETED',
                        id: 'COMPLETED',
                    },
                    {
                        name: 'CANCELLED',
                        id: 'CANCELLED',
                    },
                ]}
                translateChoice={false}
                allowEmpty={true} />
            <TextInput label="SO Transction" source="sellingOrderTransactionId"/>
            <TextInput label="B2C User" source="usedByB2CUserId"/>
            <TextInput label="Credit UUID" source="bulkCorporateCreditId"/>
            <TextInput label="Transfer UUID" source="bulkTransferUUID"/>
        </Filter>
    );
}

export function HistoryList(props) {
    return (
        <List
            {...props}
            title={`Crediti usati`}
            filters={<HistoryFilters />}
            perPage={25}
            sort={{field: 'created_at', order: 'DESC' }}
        >
            <Datagrid rowClick={null}>
                <TextField label="Credito" source="corporateCredit.name"></TextField>
                <TextField label="Usato" source="credit"></TextField>
                <TextField label="Stato" source="status"></TextField>
                <TextField label="Data Consenga" source="deliveryDate"></TextField>
                <TextField label="ID" source="id"/>
                <TextField label="SO Transction" source="sellingOrderTransactionId"></TextField>
                <TextField label="B2C User" source="usedByB2CUserId"></TextField>
                <TextField label="Credit UUID" source="bulkCorporateCreditId"></TextField>
                <TextField label="Transfer UUID" source="bulkTransferUUID"></TextField>
                <CustomEditButton />
            </Datagrid>
        </List>
    )
}
function CustomEditButton(props) {
    const dataProvider = useDataProvider();
	const [status, setStatus] = useState('INITIAL');

    const dispatch = useDispatch();
    const refresh = useRefresh();
    const { permissions } = usePermissions();

	useEffect(() => {
        if(status === 'SEND_REQUEST' && props.record && props.record.status === 'COMPLETED') {
            dataProvider(POST_OFF_RESOURCE, `${Permissions.corporateCreditHistory.feature}/${CorporateCreditHistoryActions.Cancel}/${props.record.id}`, {
                data: {}
            })
                .then(() => {
                    dispatch(showNotification(`Credito annullato`));
                    setStatus('INITIAL');
                    refresh();
                })
                .catch((e) => {
                    setStatus('INITIAL')
                });
        }
	}, [dataProvider, dispatch, props.record, refresh, status])
    if(props.record.status === 'COMPLETED' && status === 'INITIAL' && permissions && permissions.includes(`${Permissions.corporateCreditHistory.feature}-${CorporateCreditHistoryActions.Cancel}`)) {
        return <Button
            variant="outlined"
            disabled={[
                'SEND_REQUEST',
                'SENDING_REQUEST'
            ].includes(status)}
            onClick={() => {
                var confirm = window.confirm("Procedere con la cancellazione ?");
                if (confirm) {
                    setStatus('SEND_REQUEST')
                }
                else {
                    setStatus('INITIAL')
                }
            }}
        >ANNULLA</Button>
    }

    return null;
}