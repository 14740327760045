import React, { useState, cloneElement } from 'react';
import { Datagrid, List, TextField, ReferenceField, TextInput, ReferenceInput, AutocompleteInput, Filter, BooleanInput, useGetOne, Loading, BooleanField, NumberField, DateField, DateTimeInput, useDataProvider, useNotify, TopToolbar, sanitizeListRestProps, usePermissions } from 'react-admin';
import { Permissions } from '../../../../permissions/permissions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { STORAGE_TYPE } from '../../../item/itemStorable/enum';

function AccrWarehouseItemsFilters(props) {
    return (
        <Filter {...props}>
            <TextInput type="tel" label="item Id" source="item.id" alwaysOn />
            <TextInput label="item name" source="item.name" alwaysOn />
            <TextInput type="tel" label="quantità minima" source="stockBalance||gte" />
            <TextInput type="tel" label="quantità massima" source="stockBalance||lte" />
            <ReferenceInput
                alwaysOn
                filterToQuery={searchText => {
                    if (!searchText)
                        return {};

                    return {
                        name: searchText
                    }
                }}
                label="Categoria Interna"
                source="item.categoryId||eq"
                reference={`${Permissions.internalCategory.feature}`} >
                <AutocompleteInput
                    emptyText="Tutte"
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            {/**
             * to be converted in nullablebooleaninput
             * https://marmelab.com/react-admin/Inputs.html#booleaninput-and-nullablebooleaninput
             */}
            <BooleanInput
                // nullLabel="entrambi"
                // falseLabel="non riservati"
                // trueLabel="riservati"
                label="Riservati"
                source="reserved"
            />
            <TextInput label="lot name" source="lot.name||eq" />
            <DateTimeInput
                source="lot.expirationDate||gte"
                label="Scadenza Da"
            />
            <DateTimeInput
                source="lot.expirationDate||lte"
                label="Scadenza A"
            />
            <DateTimeInput
                source="inventoryDate||gte"
                label="Inventario Da"
            />
            <DateTimeInput
                source="inventoryDate||lte"
                label="Inventario A"
            />
            <AutocompleteInput emptyText="Tutte" label="Conservazione" source="item.itemStorable.storageType" choices={STORAGE_TYPE.map(storageType => {
                return {
					id: storageType,
					name: storageType
				}
			})} />
        </Filter>
    );
}

function BulkActionButtons(props) {
    let history = useHistory();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [inventoryId, setInventoryId ] = useState(null);
    const { permissions } = usePermissions();

    return (
        <>
        {permissions && permissions.includes(`warehouseInventory-${Permissions.warehouseInventory.actions.Init}`) && <Button variant="contained" color="primary" onClick={() => {
            if (props.state === 'CREATING_INVENTORY') {
                notify('Creazione inventario in corso', 'warning');
                return null;
            }

            props.setState('CREATING_INVENTORY');

            dataProvider.getMany('warehouseAccAccount', {
                ids: props.selectedIds
            }).then((response) => {
                let data = response.data.filter(record => {
                    return record.location.usage === 'Storage' || record.location.usage === 'Transit'
                });

                if (data.length === 0) {
                    throw Error();
                }

                return dataProvider(POST_OFF_RESOURCE, 'warehouseInventory/init', {
                    data: {
                        requestItems: data.map(requestItem => {
                            return {
                                locationId: props.locationId,
                                lotId: requestItem.lotId,
                            }
                        })
                    }
                })
            })
            .then(({ data }) => {
                if(data.success) {
                    props.setState('INVENTORY_CREATED');
                    history.push(`/inventory/${data.data.warehouseInventoryId}`);
                } else if(data.data && data.data.code === '20141_pending_inventory') {
                    setInventoryId(data.data.inventoryId);
                    setOpen(true);
                } else {
                    throw new Error();
                }
            })
            .catch(error => {
                if(error.code === '20141_pending_inventory') {
                    setInventoryId(error.inventoryId);
                    setOpen(true);
                }
                props.setState(null);
                notify("Errore durante la creazione dell'inventario", 'warning');
            })
            return null;
        }}>INVENTARIO</Button>}
        <Dialog
        fullScreen={false}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Per questa location è gia presente un inventario?</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={() => {
            inventoryId && history.push(`/inventory/${inventoryId}`);
          }} color="primary" autoFocus>
            Continua Inventario
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
}

function ListActions({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) {
    let history = useHistory();
    const { permissions } = usePermissions();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {permissions && permissions.includes(`warehouseInventory-${Permissions.warehouseInventory.actions.Init}`) && <Button size='small' color="primary" onClick={() => { history.push(`/create-warehouse-acc-account/${filterValues['locationId||eq']}`); }} startIcon={<AddIcon color="primary" />}>AGGIUNGI</Button>}
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
}

export function WarehouseAccAccountList(props) {
    let history = useHistory();
    const params = new URLSearchParams(props.location.search);
    const filter = params.get('filter');
    const [state, setState] = useState(null);
    const { data, loading, error } = useGetOne(`warehouseLocation`, JSON.parse(filter)["locationId||eq"]);
    const { permissions } = usePermissions();

    if(loading || !data || error) {
        return (<Loading />);
    }

    return (
        <>
            {state === 'CREATING_INVENTORY' && <Loading style={{ marginBottom: '20px' }} loadingPrimary="Creazione Inventario in corso" loadingSecondary="" />}
            {permissions && permissions.includes(`warehouseInventory-${Permissions.warehouseInventory.actions.Init}`) && <Button style={{ margin: '8px 0 16px 0' }} color="primary" onClick={() => { history.push(`/inventory-options/${data.id}`); }} startIcon={<AllInclusiveIcon />}>INVENTARIO COMPLETO</Button>}
            <List
                {...props}
                title={`${data.name} > WarehouseAccAccount`}
                filters={<AccrWarehouseItemsFilters />}
                filterDefaultValues={{ "reserved": true }}
                perPage={25}
                bulkActionButtons={<BulkActionButtons locationId={data.id} state={state} setState={setState} />}
                actions={<ListActions />}
            >
                <Datagrid rowClick={null}>
                    <TextField label="Item id" source="item.id" />
                    <TextField label="Item name" source="item.name" />
                    <TextField label="Lot" source="lot.name" />
                    <DateField label="Scadenza" source="lot.expirationDate" />
                    <TextField label="Categoria" source="item.category.name" />
                    <ReferenceField
                      link="show"
                      label={"Categoria di previsione"} 
                      source="item.forecastCategoryId" 
                      reference={Permissions.forecastCategory.feature}
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <BooleanField source="reserved" />
                    <NumberField source="financialBalance" />
                    <NumberField source="stockBalance" />
                    {permissions && permissions.includes(`warehouseInventory-${Permissions.warehouseInventory.actions.Init}`) && <SingleInventory locationId={data.id} state={state} setState={setState} />}
                </Datagrid>
            </List>
        </>
    )
}

function SingleInventory(props) {
    let history = useHistory();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [inventoryId, setInventoryId ] = useState(null);

    if (props.record.location.usage === 'Storage' || props.record.location.usage === 'Transit') {
        return <>
        <Button variant="contained" color="primary" onClick={() => {
            if (props.state === 'CREATING_INVENTORY') {
                notify('Creazione inventario in corso', 'warning');
                return null;
            }
                props.setState('CREATING_INVENTORY');
                dataProvider(POST_OFF_RESOURCE, 'warehouseInventory/init', {
                    data: {
                        requestItems: [{
                            locationId: props.locationId,
                            lotId: props.record.lotId,
                        }]
                    }
                })
                    .then(({ data }) => {
                        if(data.success) {
                            props.setState('INVENTORY_CREATED');
                            history.push(`/inventory/${data.data.warehouseInventoryId}`);
                        } else if(data.data && data.data.code === '20141_pending_inventory') {
                            setInventoryId(data.data.inventoryId);
                            setOpen(true);
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(error => {
                        if(error.code === '20141_pending_inventory') {
                            setInventoryId(error.inventoryId);
                            setOpen(true);
                        }
                        props.setState(null);
                        notify("Errore durante la creazione dell'inventario", 'warning');
                    })
            }
        }>INVENTARIO</Button>
                <Dialog
        fullScreen={false}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Per questa location è gia presente un inventario?</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={() => {
            inventoryId && history.push(`/inventory/${inventoryId}`);
          }} color="primary" autoFocus>
            Continua Inventario
          </Button>
        </DialogActions>
      </Dialog>
        </>
    }
    return null;
}