import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, required } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { Permissions } from '../../../permissions/permissions';

export function HubEdit(props) {
	return (
	<Edit {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<ReferenceInput
				filter={{"usage||eq": "Storage"}}
				filterToQuery={searchText => {
					if(!searchText)
						return {
							"usage||eq": "Storage"
						};

					return {
						"name": searchText,
						"usage||eq": "Storage"
					}
				}}
				label="Carico"
				source="loadingId"
				reference={Permissions.warehouseLocation.feature}
				validate={[required("campo obbligatorio")]} >
					<AutocompleteInput
						optionText="name"
						optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				filter={{"usage||eq": "Storage"}}
				filterToQuery={searchText => {
					if(!searchText)
						return {
							"usage||eq": "Storage"
						};

					return {
						"name": searchText,
						"usage||eq": "Storage"
					}
				}}
				label="Scarico"
				source="unLoadingId"
				reference={Permissions.warehouseLocation.feature}
				validate={[required("campo obbligatorio")]} >
					<AutocompleteInput
						optionText="name"
						optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { "name": searchText }
				}}
				label="Luogo"
				source="placeId"
				reference={Permissions.place.feature}
				validate={[required("campo obbligatorio")]} >
					<AutocompleteInput
						optionText="name"
						optionValue="id" />
			</ReferenceInput>
			<BaseEdit />
		</SimpleForm>
	</Edit>
)};