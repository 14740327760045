import React from 'react';
import { useParams } from "react-router-dom";
import BidirectionalForm from '../../../../components/BidirectionalForm';

const config = {
    type: {
        dependsOn: [],
        type: 'select',
        defaultLabel: 'Tipo di Trasferimento',
        search: false,
        disabled: true,
    },
    fromWarehouseLocationId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Trasferisci da',
        search: true,
        disabled: true,
    },
    toWarehouseLocationId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Trasferisci a',
        search: true,
        disabled: true,
    },
    transactionParentId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Parent Transaction',
        search: true,
        disabled: true,
    },
    assigneeId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Assignee (backendUser)',
        search: true,
        disabled: false,
    },
    expectedDate: {
        dependsOn: ['type'],
        type: 'datetime-local',
        defaultLabel: 'Data prevista',
        disabled: true,
    },
    externalDocument: {
        dependsOn: ['type'],
        type: 'text',
        defaultLabel: 'External document',
        disabled: false,
    },
    reasonToTransfer: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Causale',
        search: false,
        disabled: true,
    },
    deliveryMainUserId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Corriere',
        search: true,
        disabled: false,
    },
    lotLogic: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Logica Magazzino',
    },
    priority: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Priorità',
    }
}

export default function TransferEdit() {
    const { id } = useParams();
    return (<BidirectionalForm
        id={id}
        config={config}
        src='transfer'
        path='transfer/fieldOptions'
        buttonTitle='Edit'
        title='Edit Transfer' />
    );
}
