import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, DateInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export const PurchaseOrderEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />

            <ReferenceInput
                label="Luogo di Consegna"
                source="placeId"
                reference={Permissions.place.feature}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return { "name": searchText }
                }}
                validate={[required("E' obbligatorio agganciare un luogo")]} >
                    <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>

            <DateInput
                label="Data di Consegna richiesta"
                source="requiredDeliveryDate" />

            <DateInput
                label="Data di Consegna prevista"
                source="expectedDeliveryDate" />

            <TextInput 
                label="Orario di consegna massimo" 
                source="maxDeliveryHour" 
                type="time" />

            <TextInput
                label="Numero di conferma d ordine di Acquisto"
                source="confirmNumber " />

            <TextInput
                label="Nota Fornitore"
                source="supplierNote" />

            <TextInput
                label="Nota interna"
                source="internalNote" />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);