import React from 'react';
import { Datagrid, List, TextField, TextInput, DateTimeInput, DateField, ShowButton } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

const PaymentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="uuid" source="uuid||eq" alwaysOn />
        <TextInput label="transactionParentId" source="transactionParentId||eq" />
        <TextInput label="transactionId" source="transactionId||eq" />
        <DateTimeInput 
          source={`created_at||gte`}
          label={`data di creazione Da`}
        />
        <DateTimeInput 
          source={`created_at||lte`}
          label={`data di creazione A`}
        />
    </Filter>
);

export const PaymentList = props => (
    <List {...props} sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}  filters={<PaymentFilter />}>
        <Datagrid rowClick={false}>
            <TextField label="uuid" source="uuid" />
            <DateField label="date" source="created_at" showTime={true} />
            <TextField label="transactionParentId" source="transactionParentId" />
            <TextField label="transactionId" source="transactionId" />
            <TextField label="mainUserId" source="mainUserId" />
            <CustomTextField label="Lista tentativi"/>
            <ShowButton></ShowButton>
        </Datagrid>
    </List>
);

function CustomTextField({ source, record = {} }) {
	let history = useHistory();

	if(!record.transactionId) {
		return null;
	}

    return (
		<>
			<Button onClick={() => {
				history.push(`/paymentAttempt?filter={"transactionParentId||eq":"${record.transactionId}"}`);
			}}>Payment Attempt</Button>
		</>
    );
};


export default CustomTextField;