const VALIDATION_ERROR = 400;
const BUSINESS_ERROR = 409;
const FORBIDDEN_ERROR = 403;

export const STATUS_CODES_LIST = {
    VALIDATION_ERROR,
    BUSINESS_ERROR
}

export default async (url, options = {}) => {
    const token = localStorage.getItem('token');
    const hubSelected = localStorage.getItem('hubSelected') || '';

    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        })
    );
    // to work with multiple hubs send hubs.join(',')
    requestHeaders.set('X-Hub-Ids', hubSelected); 

    if (!requestHeaders.has('Content-Type') &&
        !(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    // if (options.user && options.user.authenticated && options.user.token) {
    //     requestHeaders.set('Authorization', options.user.token);
    // }
    const response = await fetch(url, { ...options, headers: requestHeaders })
    const text = await response.text()
    const o = {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
    };
    let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
    let json;
    try {
        json = JSON.parse(body);
    } catch (e) {
        // not json, no big deal
    }
    
    if (status < 200 || status >= 300) {
        if(json.code) {
            throw json;
        }
        if(VALIDATION_ERROR === status) {
            if(Array.isArray(json.message)) {
                statusText = "Errore di validazione: " + (json.message.map(data => {
                    if(data && data.code) {
                        return JSON.stringify(data);
                    }
                    if(data && data.constraints) {
                        return `invalid ${JSON.stringify(data)}`
                    } else if (
                        data && data.value
                    ) {
                        return `invalid ${JSON.stringify(data.value)}`;
                    }
                    else if(data) {
                        return data;
                    } else {
                        return null;
                    }
                }).filter(data => data).join(', ')) + ".";
            }
            else {
                statusText = json.message;
            }
        }
        else if(FORBIDDEN_ERROR === status) {
            throw json;
        }
        else if(401 === status) {
            throw json;
        }
        throw statusText;
    }
    return Promise.resolve({ status: status, headers: headers, body: body, json: json });
};