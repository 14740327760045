import React from 'react';
import { Datagrid, List, TextField, TextInput, AutocompleteInput, ReferenceField, ReferenceInput, ShowButton, usePermissions, AutocompleteArrayInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { WarehouseLocationType, WarehouseLocationUsage } from './enum';
import { Permissions } from '../../../permissions/permissions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import KitchenIcon from '@material-ui/icons/Kitchen';
import StorageIcon from '@material-ui/icons/Storage';

const WarehouseLocationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <TextInput label="Nome corto" source="shortName" />
        <AutocompleteInput label="Tipo" source="type" choices={WarehouseLocationType.map(type => {
            return {
                id: type,
                name: type
            }
        })} />
        <AutocompleteInput label="Utilizzo" source="usage" choices={WarehouseLocationUsage.map(usage => {
            return {
                id: usage,
                name: usage
            }
        })} />
        <ReferenceInput
			filterToQuery={searchText => {
				if(!searchText)
					return {};

				return {
					"name": searchText,
					
				}
			}}
            label="Parent"
            source="parentId||eq"
            reference={`${Permissions.warehouseLocation.feature}`} >
			<AutocompleteInput
				optionText="name"
				optionValue="id" />
		</ReferenceInput>
        <ReferenceInput
			filterToQuery={searchText => {
				if(!searchText)
					return {};
				return {
					"name": searchText,
				}
			}}
            label="Business unit"
            source="businessUnitId||in"
            reference={`${Permissions.businessUnit.feature}`} >
			<AutocompleteArrayInput
				optionText="name"
				optionValue="id" />
		</ReferenceInput>
    </Filter>
);

export function WarehouseLocationList(props) {
    return (
        <List {...props} actions={<ListActions />} filters={<WarehouseLocationFilter />} sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid rowClick={null}>
                <TextField label="ID" source="id" />
                <TextField label="Nome" source="name" />
                <TextField label="Nome corto" source="shortName" />
                <ReferenceField label="Parent" source="parentId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
                    <TextField source="shortName" />
                </ReferenceField>
                <TextField label="Tipo" source="type" />
                <TextField label="Utilizzo" source="usage" />
                <ShowButton />
                <ButtonList />
            </Datagrid>
        </List>
    );
}

function ButtonList({ record = {} }) {
    return (
        <>
            <ButtonInventory record={record} />
            <ButtonAccr record={record} />
        </>
    );
}

function ButtonAccr({ record = {} }) {
    let history = useHistory();

    if(record.usage !== 'Storage') {
        return null;
    }

    return (
        <Button variant="contained" color="primary" endIcon={<KitchenIcon/>} onClick={() => {
            history.push(`/accrWarehouseItems?filter={"warehouseLocationId||eq":${record.id},"quantity||gte":1}`);
        }}>Accrocchio</Button>
    );
}

export function ButtonInventory({ record = {}, title }) {
    let history = useHistory();
    const { permissions } = usePermissions();

    if(record.type === 'WarehouseLocation' && permissions && permissions.includes(`warehouseAccAccount-${Permissions.warehouseAccAccount.actions.ReadAll}`)) {
        return (
            <Button variant="contained" color="primary" endIcon={<StorageIcon/>} onClick={() => {
                history.push(`/warehouseAccAccount?filter={"locationId||eq":${record.id},"reserved":false,"status":"open"}`);
            }}>{title ? title : 'Magazzino'}</Button>
        );
    }
    return null;
}