import React from 'react';
import { Datagrid, List, TextField, DateField, ReferenceField, ReferenceInput, AutocompleteInput, DateTimeInput, AutocompleteArrayInput, NumberInput } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';

const status = [
    { id: 'DRAFT', name: 'DRAFT' },
    { id: 'TO_BE_PLANNED', name: 'TO_BE_PLANNED' },
    { id:'PLANNING', name: 'PLANNING' },
    { id: 'FAILED_TO_PLAN', name: 'FAILED_TO_PLAN' },
    { id: 'PLANNED', name: 'PLANNED' },
    { id: 'DELIVERING', name: 'DELIVERING' },
    { id: 'IN_PROGRESS', name: 'IN_PROGRESS' },
    { id: 'RETURNING', name: 'RETURNING' },
    { id:'COMPLETED', name: 'COMPLETED' },
    { id: 'CANCELLED', name: 'CANCELLED' },
];

function MissionFilter(props) {
    return (
        <Filter {...props}>
            <ReferenceInput
                alwaysOn
                filterToQuery={searchText => {
                    if (!searchText)
                        return {}

                    return {
                        "mainUser.label": searchText,
                    }
                }}
                label="Corriere"
                source="deliveryManId||eq"
                reference={Permissions.deliveryMan.feature}>
                <AutocompleteInput
                    optionText={(record) => {
                        if (!record || !record.id) {
                            return 'cancel';
                        }
                        return `${record ? `${record.mainUser.id} - ${record.mainUser.label}` : ''}`
                    }}
                    optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
				alwaysOn
                filterToQuery={searchText => {
                    if (!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Luoghi"
                source="placeId||eq"
                reference={Permissions.place.feature}
				emptyText="cancel"
				>
				
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <AutocompleteArrayInput
            alwaysOn
            label="STATO"
            source="stateMachine.status||in"
            choices={status}
            translateChoice={false}
            allowEmpty={false}/>
            <AutocompleteArrayInput
                label="NON IN STATO"
                source="stateMachine.status||notin"
                choices={status}
                translateChoice={false}
                allowEmpty={false}/>
            <DateTimeInput 
                source={`maxExecutionDate||gte`}
                label={`Data massima di inizio Da`}
                />
            <DateTimeInput 
                source={`maxExecutionDate||lte`}
                label={`Data massima di inizio A`}
            />
            <DateTimeInput 
                source={`estimatedStartDate||gte`}
                label={`Data stimata di inizio Da`}
                />
            <DateTimeInput 
                source={`estimatedStartDate||lte`}
                label={`Data stimata di inizio A`}
            />
            <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
            <NumberInput label="transactionId" source="transactionId||eq"/>
        </Filter>
    );
}

export function MissionList(props) {
	return (
		<List 
            {...props} actions={<ListActions />} 
            filters={<MissionFilter />} 
            sort={{ field: 'created_at', order: 'DESC' }}>
			<Datagrid rowClick="show">
				<ReferenceField
					 label="Luoghi"
					 source="placeId"
					 reference={Permissions.place.feature}
				>
				    <TextField source="name" />
				</ReferenceField>
				<DateField locales="it-IT" label="Data massima di inizio" source="maxExecutionDate" showTime />
				<DateField locales="it-IT" label="Data stimata di inizio" source="estimatedStartDate" showTime />
				<ReferenceField
                    link='show'
					label="Corriere"
					source="deliveryManId"
					reference={Permissions.deliveryMan.feature}
				>
					<TextField source="mainUser.label" />
				</ReferenceField>
                <TextField label="Commento" source="comment" />
                <TextField label="Stato" source="stateMachine.status" />
			</Datagrid>
		</List>
	);
}