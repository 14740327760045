import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, usePermissions, showNotification } from 'react-admin';
import moment_timezone from 'moment-timezone';
import 'moment/locale/it';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { useHistory, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Permissions } from '../../permissions/permissions';
import { POST_OFF_RESOURCE } from '../../providers/nestjs_crud';
import { Box, Button, IconButton, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';

moment_timezone.tz.setDefault('Europe/Rome');

const Resource2EntityType = {
    "place": "Place",
    "hub": "Hub",
    "deliveryMan": "DeliveryMan",
};

const getMoment = () => {
    moment_timezone.tz.setDefault('Europe/Rome');
    return moment_timezone;
};

function GoToCreate(props) {
    const history = useHistory();

    return (
        <IconButton onClick={() => {
            history.push(`/${Permissions.timeSlotRule.feature}/create?entityId=${props.entityId}&entityType=${props.entityType}`);
        }}>
            <AddIcon color="primary" />
        </IconButton>
    );
}

export function GoToList(props) {
    let history = useHistory();
    return (
        <IconButton onClick={() => {
            history.push(`/${Permissions.timeSlotRule.feature}?filter={"entityId||eq":"${props.entityId}","entityType||eq":"${props.entityType}"}`);
        }}>
            <ListIcon color="primary" />
        </IconButton>
    );
}


class ToolbarWithCreateButtonAndList extends Toolbar {
    onView = view => {
        this.props.onView(view)
    }

    render() {
        return (
            <div className='rbc-toolbar'>
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => this.navigate('TODAY')} >today</button>
                    <button type="button" onClick={() => this.navigate('PREV')}>back</button>
                    <button type="button" onClick={() => this.navigate('NEXT')}>next</button>
                </span>
                <span className="rbc-toolbar-label">{this.props.label}</span>
                {this.props.permissions && this.props.permissions.includes(`${Permissions.timeSlotRule.feature}-${Permissions.timeSlotRule.actions.CreateOne}`) && <GoToCreate
                    resource={this.props.resource}
                    entityId={this.props.entityId}
                    entityType={this.props.entityType} />}
                {this.props.permissions && this.props.permissions.includes(`${Permissions.timeSlotRule.feature}-${Permissions.timeSlotRule.actions.ReadAll}`) && <GoToList
                    resource={this.props.resource}
                    entityId={this.props.entityId}
                    entityType={this.props.entityType} />}
            </div>
        );
    }
    navigate = action => {
        this.props.onNavigate(action)
    }
}

function timeSlot2Event(start, end, timeSlot) {
    return {
        title: ``,
        start: new Date(start),
        end: new Date(end),
        availability: timeSlot.availability,
        timeSlotRules: timeSlot.timeSlotRules,
    }
}

export function TimeSlotCalendarField(props) {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const [status, setStatus] = useState('FETCH_DATA');
    const [data, setData] = useState({});
    const [dialogData, setDialogData] = useState();
    const dispatch = useDispatch();

    const moment = getMoment();
    const localizer = momentLocalizer(moment);
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const fetchData = useCallback((date) => {
        date = moment(date);
        const startDate = date.startOf('week').format('YYYY-MM-DD');
        const endDate = date.endOf('week').format('YYYY-MM-DD');
        const key = `${startDate}-${endDate}`;

        if (data[key]) {
            return null;
        }

        setStatus('FETCHING_DATA')
        dataProvider(POST_OFF_RESOURCE, `${Permissions.timeSlotRule.feature}/generate-time-slots`, {
            data: {
                startDate,
                endDate,
                entities: [
                    {
                        entityId: `${props.record.id}`,
                        entityType: Resource2EntityType[props.resource],
                    },
                ]
            }
        })
            .then(response => {
                const timeSlotRules = [];

                response.data.forEach(element => {
                    timeSlotRules.push(timeSlot2Event(element.startDateTime, element.endDateTime, element))
                })

                setData(
                    {
                        [key]: timeSlotRules,
                        ...data,
                    }
                );
                setStatus('DATA_FETCHED')
            })
            .catch(() => {
                dispatch(showNotification('Errore durante il caricamento dei dati'));
                setStatus('DATA_FETCHED')
            })
    }, [data, dataProvider, dispatch, props.record.id, props.resource, moment])

    useEffect(() => {
        if (status === 'FETCH_DATA' && permissions && permissions.includes(`${Permissions.timeSlotRule.feature}-${Permissions.timeSlotRule.actions.generateTimeSlots}`)) {
            fetchData();
        }
    }, [status, permissions, fetchData])

    if (['DATA_FETCHED', 'FETCHING_DATA'].includes(status)) {
        return (
            <div style={status === 'FETCHING_DATA' ? {
                opacity: 0.5,
                pointerEvents: 'none',
            } : {}}>
                <Calendar
                    selectable={true}
                    onSelectEvent={(event) => {
                        if(event.timeSlotRules.length === 1 && permissions && permissions.includes(`${Permissions.timeSlotRule.feature}-${Permissions.timeSlotRule.actions.UpdateOne}`)) {
                            history.push(`/${Permissions.timeSlotRule.feature}/${event.timeSlotRules[0].id}/edit`);
                        } else {
                            setDialogData(event.timeSlotRules);
                            history.push('?open=modal');
                        }
                    }}
                    components={{
                        toolbar: (toolBarProps) => <ToolbarWithCreateButtonAndList
                            {...toolBarProps}
                            entityId={props.record.id}
                            entityType={Resource2EntityType[props.resource]}
                            permissions={permissions}
                        />
                    }}
                    onNavigate={(focusDate) => {
                        fetchData(focusDate);
                    }}
                    defaultView='week'
                    localizer={localizer}
                    events={[].concat(...Object.values(data))}
                    style={{ height: '90vh' }}
                    scrollToTime={moment().toDate()}
                    eventPropGetter={function (event) {
                        var backgroundColor = event.availability === 'Available' ? '#086d40' : '#8C3E3F';
                        var style = {
                            backgroundColor: backgroundColor,
                            borderRadius: '3px',
                            color: 'white',
                            border: '1px',
                            display: 'block',
                            borderStyle: 'solid',
                        };
                        return {
                            style: style
                        };
                    }}
                />
                {params.get('open') === 'modal' && dialogData && dialogData.length > 0 && <Dialog
                    open={true}
                    onClose={history.goBack}
                    scroll={'paper'}
                    maxWidth='lg'
                >
                    <DialogTitle>
                        <Box display="flex" alignItems="center">
                            <Typography style={{width: '100%'}} align="center" variant="h6">Regole</Typography>
                            <Box>
                                <IconButton onClick={history.goBack}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Da</TableCell>
                                        <TableCell>A</TableCell>
                                        <TableCell>Giorni</TableCell>
                                        <TableCell>Ora</TableCell>
                                        {permissions && permissions.includes(`${Permissions.timeSlotRule.feature}-${Permissions.timeSlotRule.actions.UpdateOne}`) && <TableCell align="right"></TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            style={{
                                                borderStyle: 'solid',
                                                borderColor: row.availability === 'Available' ? '#086d40' : '#8C3E3F',
                                                borderLeftWidth: 16,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderTopWidth: 0,
                                            }}>
                                            <TableCell component="th" scope="row">{moment(row.startDate).format('DD-MM-YYYY')}</TableCell>
                                            <TableCell component="th" scope="row">{moment(row.endDate).format('DD-MM-YYYY')}</TableCell>
                                            <TableCell component="th" scope="row">{row.weekdays.map(weekday => {
                                                return {
                                                    '1': 'LUN',
                                                    '2': 'MAR',
                                                    '3': 'MER',
                                                    '4': 'GIO',
                                                    '5': 'VEN',
                                                    '6': 'SAB',
                                                    '0': 'DOM',
                                                }[weekday]
                                            }).join(', ')}
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row.startTime}-{row.endTime}</TableCell>
                                            {permissions && permissions.includes(`${Permissions.timeSlotRule.feature}-${Permissions.timeSlotRule.actions.UpdateOne}`) && <TableCell align="right">
                                                <Button color="primary" variant="outlined" startIcon={<EditIcon color="primary" />} onClick={() => {
                                                    history.replace(`/${Permissions.timeSlotRule.feature}/${row.id}/edit`);
                                                }}>
                                                    Modifica
                                                </Button>
                                            </TableCell>}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>}
            </div>
        );
    }
    return null;
}