import React from 'react';
import { Datagrid, List, TextField, BooleanField, TextInput, BooleanInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const PlaceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <TextInput label="Indirizzo" source="address" />
        <BooleanInput label="Può acquistare" source="canPurchase" defaultValue={true}></BooleanInput>
        <BooleanInput label="Può vendere" source="canSell" defaultValue={true}></BooleanInput>
    </Filter>
);

export const PlaceList = props => (
    <List {...props} actions={<ListActions />}  filters={<PlaceFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Indirizzo" source="address" />
            <BooleanField label="Può acquistare" source="canPurchase" /> 
            <BooleanField label="Può vendere" source="canSell" /> 
        </Datagrid>
    </List>
);